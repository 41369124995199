<script>
export default {
    computed: {
        version() {
            return process.env.VUE_APP_VERSION;
        },
    },
}
</script>

<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="text-center text-lg-end ">
                        Powered by
                        <a href="https://www.azerion.com/" target="_blank" class="text-reset">©Azerion {{ new Date().getFullYear()}}</a> <span class="ms-4">Version: {{version}} </span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>